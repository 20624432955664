import { RootState } from "@general/redux/store";
import { useSelector } from "react-redux";
import { isSMOrLess, stateSMOrLessChangeComparation } from '@general/services/grid';

export function useIsSMOrLess() {
    const gridSzie = useSelector((state: RootState) => state.gridSize.value, stateSMOrLessChangeComparation)
    return isSMOrLess(gridSzie);
}

export function useCountryIso() {
    return useSelector((state: RootState) => state.countryIso.value)
}