import { FieldError } from "react-hook-form"
import CryptoJS from "crypto-js"

export function isOnServerside() {
    return typeof window === 'undefined'
}

export function reactFormErrorToBoolean(error: FieldError | undefined): boolean {
    if (error != undefined) {
        return true
    }
    return false
}
export type TApiResponseValidationResult = {
    success: boolean
    errorCode: number

}

export function getHash(value: string) {
    if (typeof process.env.HASH_SECRET != 'undefined') {
        return CryptoJS.HmacSHA256(value, process.env.HASH_SECRET).toString(CryptoJS.enc.Hex);
    }
    return CryptoJS.SHA256(value);
}