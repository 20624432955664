'use client'
import { useTranslation } from "@general/i18n/client"
import { RootState } from "@general/redux/store"
import { getAvailableLangs, getLangInfoByIso } from "@general/services/lang"
import { getPathWithoutLang } from "@general/services/path"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { Dropdown } from "react-bootstrap"
import { DropDirection } from "react-bootstrap/esm/DropdownContext"
import { useSelector } from "react-redux"

function LangOptions(props: {
    short: boolean
    path: string
    currenLangIso: string
}): JSX.Element {
    const availableLangs = getAvailableLangs()
    const content: JSX.Element[] = []
    const currentLang = useSelector((state: RootState) => state.lang.value)
    const { t } = useTranslation(currentLang, "common")

    let key: keyof typeof availableLangs, lang: any
    for (key in availableLangs) {
        lang = availableLangs[key]
        content.push(
            <Dropdown.Item key={lang.iso} as="span">
                {lang.iso === props.currenLangIso ?
                    props.short ? lang.iso : lang.label
                    :
                    <Link key={lang.iso} title={t('Change language to ') + lang.iso} className="text-decoration-none w-100 d-block text-body" href={'/' + lang.iso + props.path}>
                        {props.short ? lang.iso : lang.label}
                    </Link>
                }
            </Dropdown.Item>
        )
    }
    return <>{content}</>
}
export function LangSwitcher(props: {
    short?: boolean
    varian?: string
    className?: string
    drop?: DropDirection
}): JSX.Element {
    const short = props!.short ?? false
    const variant = props!.varian ?? 'none'
    const className = (props!.className ?? '') + ' lang-switcher'
    const drop = props!.drop ?? 'down-centered'
    const currenLangIso = useSelector((state: RootState) => state.lang.value)
    const currentLangInfo = getLangInfoByIso(currenLangIso)
    const pathnameWithoutLang = getPathWithoutLang(usePathname())
    const { t } = useTranslation(currenLangIso, "common")
    return <><Dropdown
        className={className}
        drop={drop}
    >
        <Dropdown.Toggle
            variant={variant}
            className="w-100"
            aria-label={t("Site. Open language menu")}
        >
            {currentLangInfo ? short ? currentLangInfo.iso : currentLangInfo.label : ''}
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <LangOptions short={short} path={pathnameWithoutLang} currenLangIso={currenLangIso} />
        </Dropdown.Menu>
    </Dropdown>
    </>
}