'use client'
import { useTranslation } from "@general/i18n/client";
import { RootState } from "@general/redux/store";
import Link from "next/link";
import { useSelector } from "react-redux";
import logoLong from '@general/assets/logo_long.svg'
import logoShort from '@general/assets/logo_short.svg'
import Image from 'next/image'
import { useIsSMOrLess } from "@general/services/hooks";
import { LangSwitcher } from "@general/components/client_side/LangSwitcher";
import { MobileMenu, TopMenu } from "@general/components/client_side/Menu";

export function Header(): JSX.Element {
    const selectedLang = useSelector((state: RootState) => state.lang.value)
    const forMobile = useIsSMOrLess();
    const { t } = useTranslation(selectedLang, 'common')   

    return <nav className="main_menu">
        <div className="container">
            <Link href={'/' + selectedLang} title={t('Home WM Web Agency')}>
                <Image 
                    className="header_logo" 
                    alt={t('Logo WM Web Agency')}
                    src={forMobile ? logoShort.src : logoLong.src} 
                    width={forMobile ? 50 : 131}
                    height={forMobile ? 50 : 50}
                    />
            </Link>
            <MobileMenu/>
            <LangSwitcher short={true}/>
            <TopMenu />
        </div>
    </nav>
}