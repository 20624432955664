'use client'
import Modal from 'react-bootstrap/Modal';
import { ContactForm } from './ContactForm';
import { HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@general/redux/store';
import { useTranslation } from '@general/i18n/client';
export interface ContactFormModalProps extends HTMLAttributes<HTMLDivElement> {
    show: boolean
    onHide: any,
    place_from?: string
}
export function ContactFormModal(props: ContactFormModalProps) {
    const selectedLang = useSelector((state: RootState) => state.lang.value)
    const { t } = useTranslation(selectedLang, 'common')
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title className='text-center w-100'>
                    {t('Reach Out Efficiently:')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ContactForm place_from={props.place_from} />
            </Modal.Body>
        </Modal>
    );
}