import * as z from 'zod'
export function isCustomerName(): z.ZodEffects<z.ZodString, string, string> {
    return z.string().min(2).max(128).refine((value: string) => {
        const pattern = new RegExp('^[^0-9!<>,;?=+()@#"°{}_$%:]*$', 'gu');
        return pattern.test(value)
    })
}
export function isPhoneNumber(): z.ZodEffects<z.ZodString, string, string> {
    return z.string().min(12).max(34).refine((value: string) => {
        const pattern = new RegExp('^[+0-9. ()/-]*$', 'gu');
        return pattern.test(value)
    })
}
export function isMessage(): z.ZodEffects<z.ZodString, string, string> {
    return z.string().min(3).max(1000).refine((value: string) => {
        const pattern = new RegExp('[<>{}]', 'gu');
        return !pattern.test(value)
    })
}