'use client'

import {createInstance, i18n} from 'i18next'
import { initReactI18next, useTranslation as useTranslationOrg } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { getOptions, languages, fallbackLang } from './settings'

const i18nextInstances: { [key: string]: i18n; } = {}
let key 
for (key in languages) {
    const i18nInstance = createInstance()
    const lang = languages[key]
    i18nInstance
    .use(initReactI18next)
    .use(resourcesToBackend((lng: string, ns:string) => import(`./locales/${lng}/${ns}.json`)))
    .init(getOptions(lang))
    i18nextInstances[lang] = i18nInstance
}

export type TTranslationOptions = {
    keyPrefix?: string
    i18n?: any
}

export function useTranslation(lng: string, ns: string | string[], options: TTranslationOptions = {}) {
    let i18n
    if (lng in i18nextInstances) {
        i18n = i18nextInstances[lng]
    } else {
        i18n = i18nextInstances[fallbackLang]
    }
    options.i18n = i18n
    return useTranslationOrg(ns, options)
}