import type { NextRequest } from 'next/server'
import { fallbackLang, languages } from '@general/i18n/settings'
import { getCookieValue } from '@general/services/cookie'
export const langCookieName = 'lang'
let currentLang = fallbackLang
export function getLangFromRequest(request: NextRequest): string {
    const url = request.nextUrl.clone()
    const langFromPath = languages.find(
        (lang: string) => url.pathname.startsWith(`/${lang}/`) || url.pathname === `/${lang}`
    )

    if (langFromPath) {
        currentLang = langFromPath
        return langFromPath
    }


    const langFromCookie = getCookieValue(langCookieName, request.cookies)
    if (langFromCookie) {
        const matchedCookieLang = languages.find((lang: string) => {
            if (lang === langFromCookie) {
                return true
            }
        })
        if (matchedCookieLang) {
            // currentLang = matchedCookieLang
            return matchedCookieLang
        }
    }

    return fallbackLang
}
export type LangInfo = {
    label: string
    iso: string
}
export function getAvailableLangs(): { [k: string]: LangInfo } {
    return {
        "en": {
            label: "English",
            iso: "en"
        },
        "ua": {
            label: "Українська",
            iso: "ua"
        },
    }
}

export function langAvailble(lang: string): boolean {
    const languages = getAvailableLangs()
    return lang in languages
}

export function getDefaultLang(): string {
    return fallbackLang;
}

export function getLangInfoByIso(langIso: string): LangInfo | undefined {
    const languages = getAvailableLangs()
    if (langIso in languages) {
        return languages[langIso]
    }
    return undefined
}

export function getBCP47(langIso: string): string{
    switch(langIso.toLowerCase()) {
        case 'en':
            return 'en-GB'
        case 'ua':
            return 'uk-UA'
    }
    return ''
}