import { getAvailableLangs } from "@general/services/lang"

export function getPathWithoutLang(path: string): string {
    const langs = getAvailableLangs()
    let iso;
    for (iso in langs) {
        const regexpr = new RegExp('^\/'+iso)
        path = path.replace(regexpr, '')
    }
    return path
}