import * as z from 'zod'
import { isCustomerName, isMessage, isPhoneNumber } from "@general/services/zod_primitives";
export const ContactSchema = z.object({
    email: z.string().email(),
    phone: isPhoneNumber(),
    name: isCustomerName(),
    message: isMessage(),
})

export type TContactData = z.infer<typeof ContactSchema>

export function isValid(schema: z.ZodObject<any>, object: any): boolean {
    try {
        schema.parse(object)
        return true
    } catch (error) {
        return false
    }
}

export function isValidIP(IP: string): boolean {
    try {
        const schema = z.string().ip()
        schema.parse(IP)
        return true
    } catch (error) {
        return false
    }
}