'use client'
import { useTranslation } from "@general/i18n/client";
import { RootState } from "@general/redux/store";
import { useSelector } from "react-redux";
import { useIsSMOrLess } from "@general/services/hooks";
import "@general/styles/components/top-menu.scss"
import "@general/styles/components/mobile-menu.scss"
import Link from "next/link";
import { HTMLAttributes, MouseEventHandler, useId, useState } from "react";

const timoutsFor: {
    [key: string]: any
} = {}

export function TopMenu(): JSX.Element {
    const [selectedId, setSelectedId] = useState('');
    const selectedLang = useSelector((state: RootState) => state.lang.value);
    const forMobile = useIsSMOrLess();
    const { t } = useTranslation(selectedLang, 'menu');
    const serviceMenuId = useId();


    function handleMouseEnter(id: string) {
        if (typeof timoutsFor[id] !== 'undefined') {
            clearTimeout(timoutsFor[id])
        }
        setSelectedId(serviceMenuId)
    }
    function handleMouseLeave(id: string) {
        if (typeof timoutsFor[id] !== 'undefined') {
            clearTimeout(timoutsFor[id])
        }
        timoutsFor[id] = setTimeout(() => {
            if (id == selectedId) {
                setSelectedId('')
            }
        }, 500)
    }

    return <>
        {!forMobile &&
            <nav className="top-menu">
                <ul className="first-level">
                    <li className={selectedId == serviceMenuId ? 'opened' : ''}>
                        <span className="title"
                            onMouseEnter={() => handleMouseEnter(serviceMenuId)}
                            onMouseLeave={() => handleMouseLeave(serviceMenuId)}
                        >{t('Services', { ns: 'menu' })}</span>

                        <ServicesMenu
                            onMouseEnter={() => handleMouseEnter(serviceMenuId)}
                            onMouseLeave={() => handleMouseLeave(serviceMenuId)}
                        />
                    </li>
                </ul>
            </nav>
        }
    </>
}

export function MobileMenu(): JSX.Element {
    const [opened, setOpened] = useState(false);
    const forMobile = useIsSMOrLess();

    return <>
        {forMobile &&
            <>
                <div className="mobile-menu-button" onClick={() => setOpened(!opened)}><i className={opened ? 'icon-cross' : 'icon-menu-burger'}></i></div>
                <div className={'mobile-menu' + (opened ? ' opened' : '')}>
                    <nav className="mobile-menu-wrapper">
                        <ServicesMenu forMobile={true} />
                    </nav>
                </div>

            </>
        }
    </>
}

export function ServicesMenu({ onMouseEnter, onMouseLeave, forMobile }: { onMouseEnter?: MouseEventHandler, onMouseLeave?: MouseEventHandler, forMobile?: boolean }): JSX.Element {
    const selectedLang = useSelector((state: RootState) => state.lang.value);
    const { t } = useTranslation(selectedLang, 'menu');
    return <div className="second-level"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
    >
        <ul>
            <SecondLevel forMobile={forMobile} title={t('E-commerce', { ns: 'menu' })}>
                <ul className="third-level">
                    <li>
                        <Link href={'/' + selectedLang + '/magento'} title={t('Shop on Magento development', { ns: 'menu' })}>{t('Shop on Magento development', { ns: 'menu' })}</Link>
                    </li>
                    <li>
                        <Link href={'/' + selectedLang + '/prestashop'} title={t('Shop on Prestashop development', { ns: 'menu' })}>{t('Shop on Prestashop development', { ns: 'menu' })}</Link>
                    </li>
                </ul>
            </SecondLevel>
            <SecondLevel forMobile={forMobile} title={t('Business solutions', { ns: 'menu' })}>
                <ul className="third-level">
                    <li>
                        <Link href={'/' + selectedLang + '/odoo'} title={t('ERP/SRM on Odoo', { ns: 'menu' })}>{t('ERP/SRM on Odoo', { ns: 'menu' })}</Link>
                    </li>
                    <li>
                        <Link href={'/' + selectedLang + '/parser'} title={t('Crawling tools for monitoring', { ns: 'menu' })}>{t('Crawling tools for monitoring', { ns: 'menu' })}</Link>
                    </li>
                    <li>
                        <Link href={'/' + selectedLang + '/ai_integration'} title={t('AI Integration Services', { ns: 'menu' })}>{t('AI Integration Services', { ns: 'menu' })}</Link>
                    </li>
                </ul>
            </SecondLevel>
            <SecondLevel forMobile={forMobile} className="last" title={t('Marketing', { ns: 'menu' })}>
                <ul className="third-level">
                    <li>
                        <Link href={'/' + selectedLang + '/google_ads'} title={t('Google Ads Campaign Services', { ns: 'menu' })}>{t('Google Ads Campaign Services', { ns: 'menu' })}</Link>
                    </li>
                </ul>
            </SecondLevel>
        </ul>
    </div>
}
export interface SecondLevelProps extends HTMLAttributes<HTMLLIElement> {
    forMobile?: boolean,
    title?: string
}
export function SecondLevel({ forMobile, title, className, children, ...props }: SecondLevelProps): JSX.Element {

    const [opened, setOpened] = useState(false);
    const icons = <><i className="icon-angle-regular-down"></i><i className="icon-angle-regular-up"></i></>
    const classNames = `${className || ''} ${opened ? 'opened' : ''}`;

    const handleToggle = () => {
        setOpened(!opened);
    };

    return <li {...props} className={classNames}>
        <div className="title" onClick={handleToggle}>{title}{forMobile && icons}</div>
        {children}
    </li>
}

