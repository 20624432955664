import { SM, XS } from "@general/constants/general";

export function isSMOrLess(gridType: string): boolean {
    switch (gridType) {
        case SM:
        case XS:
            return true
    }
    return false
}
export function stateSMOrLessChangeComparation(oldGridType: string, newGridType: string): boolean {
    return isSMOrLess(oldGridType) === isSMOrLess(newGridType)
}